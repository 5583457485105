html,
body {
  font-family: 'HCA-Mark' !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Table */
table {
  display: flex;
  flex-direction: column;
}

table thead {
  width: 100%;
}

table thead tr {
  display: flex;
}

tbody {
  display: flex;
  flex-direction: column;
}

table tbody {
  width: 100%;
}

table tbody tr {
  display: flex;
}

.neu-table .unit-row {
  display: flex;
  min-height: 71px;
  width: 100%;
}

.neu-table .unit-row > tr {
  display: flex;
  width: 100%;
  border-top: none;
  border-bottom: none;
}

.neu-table .unit-header {
  width: 100%;
}

.neu-table.sticky-header .unit-header thead {
  display: flex;
  width: 100%;
}

.neu-table .unit-header thead .unit-column :is(th, td) {
  display: flex;
  width: 100%;
}

.neu-table .column-header,
.neu-table .unit-content .unit-column :is(th, td) {
  display: flex;
  width: 100%;
}

.neu-table .unit-content .unit-column :is(th, td) {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: none;
  border-top: 1px solid var(--table-border);
}

/* Content */
tr div td {
  justify-content: end;
}

/* Icons */
.neu-table .column-sort--label::after {
  margin-left: 0rem !important;
}
/* ToolTip */
.neu-tooltip--label {
  min-width: 690px !important;
}

.ui.multiple.dropdown > .label {
  text-decoration: none !important;
}
