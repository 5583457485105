.input_styled:focus {
  border: 1px solid #0085ca !important;
  outline: none;
}

.input_styled:active {
  border: 1px solid #0085ca !important;
  outline: none;
}

.input_styled:target {
  border: 1px solid #0085ca !important;
  outline: none;
}
