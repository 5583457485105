@import url('@neutron/core/dist/assets/neutron.min.css');

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Slide-Left {
  0% {
    right: -420px;
  }
  80% {
    right: 5px;
    width: 425px;
  }
  100% {
    right: 0;
    width: 420px;
  }
}
@keyframes Report-Slide-Left {
  0% {
    left: 0px;
  }
  100% {
    left: -420px;
    opacity: 0;
  }
}
@keyframes Report-Slide-Right {
  0% {
    left: -420px;
  }
  80% {
    left: 5px;
    width: 425px;
  }
  100% {
    left: 0;
    width: 420px;
  }
}
.reportSlideRight {
  animation: Report-Slide-Right 0.5s ease-out forwards;
}
.reportSlideLeft {
  animation: Report-Slide-Left 0.3s ease-in forwards;
}

.slideLeft {
  animation: Slide-Left 0.5s ease-out forwards;
}

@keyframes Slide-Left-Full {
  0% {
    right: -100vw;
  }
  70% {
    right: 2.5px;
    width: 101vw;
  }
  100% {
    right: 0;
    width: 100vw;
  }
}

.slideLeftFull {
  animation: Slide-Left-Full 0.3s ease-out forwards;
}
@keyframes Slide-Right {
  0% {
    right: 0px;
  }
  100% {
    right: -420px;
    opacity: 0;
  }
}
.slideRight {
  animation: Slide-Right 0.3s ease-in forwards;
}
@keyframes Slide-Right-Full {
  0% {
    right: 0px;
  }
  100% {
    right: -100vw;
    opacity: 0;
  }
}
.slideRightFull {
  animation: Slide-Right-Full 0.3s ease-in forwards;
}

.bb {
  border-bottom: 2px solid #ddd;
}
.neu-chip__avatar_modified {
  position: absolute;
  left: 2px;
  color: #898b8e;
  font-size: 28px !important;
  height: 28px !important;
  min-width: 28px !important;
  border-radius: 50%;
}
.neu-tab--navy-active {
  color: rgb(246, 246, 246) !important;
}
.white {
  color: white !important;
  cursor: pointer;
}
.grey {
  color: darkgrey !important;
}
.genericDropdown {
  border: solid lightgrey 1px !important;
  border-radius: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-top: 10px !important;
  color: #1f2532 !important;
  font-family: HCA-Mark, Arial, sans-serif !important;
  font-size: 16px !important;
}

.genericLabel {
  padding-top: 20px !important;
  color: #1f2532 !important;
  font-family: HCA-Mark, Arial, sans-serif !important;
  font-size: 14px !important;
}

.reportSlidingPaneDropdown {
  min-height: auto !important;
}
.reportSlidingPaneDropdown > input.search {
  width: auto !important;
}

.siteDropdown > input[attribute='value'],
.siteDropdown > div[attribute='aria-live'] {
  color: #ffffff !important;
  font-family: 'HCA-Mark';
  font-size: 16px;
}
.ui.search {
  font-size: 16px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
}
.deleteUserModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.ui.upward.dropdown > .menu {
  bottom: auto;
  top: 100%;
}

@keyframes snackbar-animation {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.snackbar {
  animation-name: snackbar-animation;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}
.modal-report {
  position: fixed;
  top: 120px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 14px;
  box-sizing: content-box;
}
.inner-shadow {
  -moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.outer-shadow {
  -moz-box-shadow: 5px 2px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 2px 5px rgba(0, 0, 0, 0.2);
}
.modal-main {
  position: absolute;
  display: flex;
  background: white;
  width: 80%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.dropdownList :hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.testingSelected {
  background-color: blue;
}

.cursor {
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: black !important;
  font-size: 1.1rem !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: black !important;
  font-size: 1.1rem !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: black !important;
  font-size: 1.1rem !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: black !important;
  font-size: 1.1rem !important;
}
/* Dropdown Button */
.dropbtn {
  width: 160px;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.unit-dropdown {
  position: relative;
  display: inline-block;
  font-size: 16px;
  background-color: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 160px;
}

/* Dropdown Content (Hidden by Default) */
.unit-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  font-family: 'HCA-Mark';
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.unit-dropdown-content a {
  color: black;
  background-color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.unit-dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.showUnit {
  display: block;
}

.dropdown-button {
  color: white;
  display: inline-block;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: auto;
}

.dropdown-content {
  display: none;
  background-color: #f1f1f1;
  width: 250px;
}

.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content li:hover {
  background-color: lightblue;
  border-radius: 5px;
}

.admin-dropdown-button {
  color: black;
  display: inline-block;
  font-size: 16px;
  border: none;
  cursor: pointer;
  padding-right: 30px;
}

.show {
  display: block;
}

.neu-button-toggles .neu-button-toggles__button {
  display: inline-block;
  margin-left: -3px;
  margin-right: -3px;
  border-radius: 0px;
  border: 2px solid #00558c;
  padding-top: 2px;
  padding-bottom: 2px;
}

.neu-button-toggles .neu-button-toggles__button:first-child {
  border-left-width: 2px;
  border-radius: 5px 0 0 5px;
}

.neu-button-toggles .neu-button-toggles__button:last-child {
  border-radius: 0 5px 5px 0;
}

.neu-button-toggles .neu-button-toggles__button-active {
  background-color: #00558c;
  color: #ffffff;
}

.neu-button-toggles .neu-button-toggles__button:hover,
.neu-button-toggles .neu-button-toggles__button:focus {
  background-color: #bfe0f1;
  color: #00558c;
}

.neu-button-toggles .neu-button-toggles__button .neu-button-group__icon-left {
  margin-right: 5px;
  margin-left: 0;
}

.neu-button-toggles .neu-button-toggles__button .neu-button-group__icon-right {
  margin-left: 5px;
  margin-right: 0;
}

.neu-tab--navy.sc-neu-tab-h:hover:not([disabled]) {
  color: rgb(246, 246, 246) !important;
}
a.neu-tab--is-active {
  color: rgb(246, 246, 246) !important;
}

/*  overriding Semantic UI to add ellipsis on long text in dropdowns */
.ui.selection.dropdown > .menu .item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui.search.dropdown > .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 240px;
}

.ui.selection.visible.dropdown > .text {
  padding-right: 0px !important;
}

/* overriding Neutron to match original design */

.neu-link {
  // color: #00558c;
  text-decoration: none;
}

.ui.selection.dropdown > .menu .item {
  font-weight: inherit;
  font-size: 16px;
}
