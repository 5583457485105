.radio {
  display: inline-block;
}
.radio .radio__button {
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 2px solid #bcbcbc;
  border-radius: 50px;
}
.radio .radio--is-checked {
  position: relative;
  background: #ffffff;
  border: 2px solid #0085ca;
}
.radio .radio--is-checked:after {
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  background: #0085ca;
  border: 2px solid #ffffff;
  border-radius: 50px;
}
.radio .radio__label {
  margin-left: 5px;
  margin-right: 10px;
  text-transform: capitalize;
}
.radio:focus,
.radio:active {
  background: transparent;
  outline: none;
}
