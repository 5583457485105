/* Component Variables & Mixins
================================================================================ */
$checkbox-size: 18px;

/* Default Checkbox
	================================================================================ */
.styledCheckbox {
  display: inline-block;

  .styledCheckbox__box {
    display: inline-block;
    vertical-align: sub;
    width: $checkbox-size;
    height: $checkbox-size;
    background: white;
    border: 2px solid #bcbcbc;
    border-radius: 3px;
  }

  .styledCheckbox__label {
    vertical-align: middle;

    padding: 0px 5px;
    font-size: 14px;
    color: gray;
    font-weight: 400;
    border-radius: 5px;
    text-transform: capitalize;
  }

  .styledCheckbox__box-is-checked {
    position: relative;
    background: #0085ca;
    border: 2px solid #0085ca;

    .neutron-checkbox__label {
      color: #0085ca !important;
    }

    &:after {
      content: '';
      position: absolute;
      left: 1px;
      top: 8px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow:
        -1px 0 0 white,
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white,
        4px -10px 0 white,
        4px -12px 0 white;
      transform: rotate(45deg);
    }
  }
}
